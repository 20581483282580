<template>
    <auth-default>
        <b-card>
            <b-card-title
                class="mb-4 font-weight-bold"
                title-tag="h4"
            >
                {{ $t('auth.login.title') }}
            </b-card-title>

            <b-form @submit.prevent="submitForm()">
                <b-form-group
                    :invalid-feedback="form.errors.email || form.errors.message"
                    :label="$t('inputs.email.label')"
                    :state="!(form.errors.email || form.errors.message)"
                >
                    <b-form-input
                        v-model="form.email"
                        :placeholder="$t('auth.inputs.email.placeholder')"
                        type="email"
                    />
                </b-form-group>

                <b-form-group
                    :invalid-feedback="form.errors.password"
                    :label="$t('inputs.password.label')"
                    :state="!form.errors.password"
                >
                    <b-input-group>
                        <b-form-input
                            v-model="form.password"
                            :placeholder="$t('auth.inputs.password.placeholder')"
                            type="password"
                        />

                        <b-input-group-append>
                            <b-button
                                v-t="'auth.login.actions.forgotPassword'"
                                class="text-primary"
                                :to="{ name: 'auth.password.forgot' }"
                                variant="link"
                            />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <btn-resource
                    block
                    class="mt-4"
                    type="submit"
                    :resource="form"
                    variant="primary"
                >
                    {{ $t('auth.login.actions.login') }}
                </btn-resource>
            </b-form>
        </b-card>
    </auth-default>
</template>

<script>
import AuthDefault from '@/components/auth/Default';
import Model from '@/models/vue-mc/Model';
import Authenticates from '@/components/auth/mixins/Authenticates';
import RecordsErrorMessage from '@/components/common/mixins/RecordsErrorMessage';

export default {
    name: 'Login',
    components: {AuthDefault},
    mixins: [Authenticates, RecordsErrorMessage],
    data() {
        return {
            form: new Model(
                {
                    email: '',
                    password: '',
                },
                null,
                {endpoint: 'auth/login'},
            ),
        };
    },
    methods: {
        async submitForm() {
            await this.form.save();

            if (!this.form.user.canLoginWeb) {
                return this.form.setErrors({
                    message: this.$t('auth.messages.userCantLoginWeb'),
                });
            }

            const data = {
                user: this.form.user,
                apiToken: this.form.apiToken,
            };

            this.authenticate(data, 'login');
        },
    },
};
</script>
